/* 
Program Name : ChangeInitiation.jsx
Written By: Snehal Kurhade
Written on: 16 MAR 2022 
System Name: Change Management App
Purpose:  Screen to add new chnage Note
Type of Program: React Frontend
*/
import React, { useState, useEffect, } from "react";
import { Table, Button, Col, Container, Form, Row, Stack } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import 'react-responsive-combo-box/dist/index.css'
import "../App_Arun.css";
import axios from "axios";
import 'react-dropzone-uploader/dist/styles.css';
import { FileEarmarkText, Trash } from "react-bootstrap-icons";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Dropzone from 'react-dropzone-uploader';
// import {DatePicker }from "react-datepicker";
import DatePicker from 'react-date-picker';
import moment from 'moment';
import $ from 'jquery';
// import Moment from 'react-moment';

import {
    EnrollNewChangeInitiation,
    getSelectedProjectRevisionNo,
    getSelectedProjectTimeandCost,
    getdetails,
} from "../services/ChangeInitiation-service";

import {
    getProject,
    getProjectDetails,
} from "../services/project-service";

import {
    getTeams,
} from "../services/Teams-service";

import {
    getWorkPackage,
} from "../services/WorkPackage-service";

import {
    getWorktype,
} from "../services/WorkType-service";

import {
    getAll,
    getSectionEmail,
} from "../services/SectionMaster-service";

import {
    getPdaSpocName,
    getActivePdaSpocName,
    getEmailId
} from "../services/PDASpoc-service";

import {
    getOtherDiv,
    getOtherDivEmail,
} from "../services/OtherDivisions-service";

import {
    getFinanceTeam,
    getFinanceEmail,
} from "../services/finance-service";

import {
    getEmpdetails,
} from "../services/DivisionEmail-service";

import {
    getApprovalHeads,
} from "../services/ApprovalHeads-service";

//const axios = require("axios").default;


export default function ChangeInitiation() {
    const navigate = useNavigate();
    let query = window.location.href
    let indication = "";
    indication = query.split("?")
    if (indication.length > 1) {
        navigate("/Raised");
    }

    useEffect(() => {
        fetchTeam(); fetchworktype(); fetchWorkPackage(); fetchProjectdetails(); fetchSectionEmailOptions(); fetchProjectData();
        fetchConstructionSectionTeamOptions(); fetchOtherDivisionsOptions();
        fetchPDASpocName(); fetchFinanceTeam(); FetchUsetDetails(); FetchApprovalHeads();
    }, []);


    const dateToFormat = '1976-04-19';

    // // 1st screen
    const [WorkType, setWorkType] = useState("");
    const [WorkTypeOptions, setWorkTypeoptions] = useState([]);
    const [TeamName, setTeamName] = useState("");
    const [TeamNameOptions, setTeamOptions] = useState([]);
    const [ProjectName, setProjectName] = useState("");
    const [ProjectNameOptions1, setProjectNameOptions1] = useState([]);
    const [ProjectNumber, setProjectNumber] = useState("");
    const [ProjectDetails, setProjectdetails] = useState([]);
    const [ProjectOwner, setProjectOwner] = useState("");
    const [DMCMemberOwner, setDMCMemberOwner] = useState("");
    const [ChangeInitiationOwner, setChangeInitiationOwner] = useState("");
    const [ChangeInitiationOwnerEmail, setChangeInitiationOwnerEmail] = useState("");
    const [DMCMember, setDMCMember] = useState("");
    const [ChangeRequestNumber, setChangeRequestNumber] = useState("");
    const [RevisionNo, setRevisionNo] = useState(0);
    const [ChangeRequestDate, setChangeRequestDate] = useState("");
    const [WorkPackageOptions, setWorkPackageOptions] = useState([]);
    const [WorkPackage, setWorkPackage] = useState("");
    const [Existing, setExisting] = useState("");
    const [Proposed, setProposed] = useState("");
    const [Attachment, setAttachment] = useState("");
    const [JustificationOfChange, setJustificationOfChange] = useState("");
    const [AssumptionsConstraints, setAssumptionsConstraints] = useState("");
    const [KeyRisks, setKeyRisks] = useState("");
    // 2nd screen
    const [MaxRevisionNo, setMaxRevisionNo] = useState(0);
    const [OriginalchangenoAndDate, setOriginalchangenoAndDate] = useState("");
    const [Original_time, setOriginal_time] = useState("");
    const [Original_Cost, setOriginal_Cost] = useState("");
    const [R1_ChangeNoAndDate, setR1_ChangeNoAndDate] = useState("");
    const [R1_Time, setR1_Time] = useState("");
    const [R1_Cost, setR1_Cost] = useState("");
    const [R2_ChangeNoAndDate, setR2_ChangeNoAndDate] = useState("");
    const [R2_Time, setR2_Time] = useState("");
    const [R2_Cost, setR2_Cost] = useState("");
    const [R3_ChangeNoAndDate, setR3_ChangeNoAndDate] = useState("");
    const [R3_Time, setR3_Time] = useState("");
    const [R3_Cost, setR3_Cost] = useState("");
    const [R4_ChangeNoAndDate, setR4_ChangeNoAndDate] = useState("");
    const [R4_Time, setR4_Time] = useState("");
    const [R4_Cost, setR4_Cost] = useState("");
    // 3rd screen

    const [ChangeFesibility_releventStakeholders, setChangeFesibility_releventStakeholders] = useState("");
    const [tmpGodrejConstructionSections, settmpGodrejConstructionSections] = useState("");
    const [GodrejConstructionSectionsEmailOptions, setGodrejConstructionSectionsEmailOptions] = useState([]);
    const [tmpGodrejConstructionSectionsEmail, settmpGodrejConstructionSectionsEmail] = useState("");
    const [ConstructionSectionTeamOptions, setConstructionSectionTeamOptions] = useState([]);
    const [SelectedConstructionSection, setSelectedConstructionSection] = useState([]);
    // const [CountOfStakeHolders, setCountOfStakeHolders] = useState("");
    const [FeasibilityReportCount, setFeasibilityReportCount] = useState("");
    const [Other, setOther] = useState("");//columnname
    const [OtherDivisionOptions, setOtherDivisionOptions] = useState([]);
    const [OtherDivision, setOtherDivision] = useState("");
    const [OtherDivisionEmailOptions, setOtherDivisionEmailOptions] = useState([]);
    const [OtherDivisionEmail, setOtherDivisionEmail] = useState("");
    const [OriginalCommitment_Time, setOriginalCommitment_Time] = useState("");
    const [OriginalCommitmentCost, setOriginalCommitmentCost] = useState("");
    const [LastApproved_Time, setLastApproved_Time] = useState("");
    const [LastApprovedCost, setLastApprovedCost] = useState("");
    const [NewProposed_time, setNewProposed_time] = useState("");
    const [NewProposed_Cost, setNewProposed_Cost] = useState("");
    const [Impact_EndDate, setImpact_EndDate] = useState("No");
    const [Impact_Budget, setImpact_Budget] = useState("No");
    const [RevisedBudget, setRevisedBudget] = useState("");
    const [RevisedEndDate, setRevisedEndDate] = useState("");

    const [PDASpocNameOptions, setPDASpocNameOptions] = useState([]);
    const [PDASpocName, setPDASpocName] = useState("");
    const [IsFinance, setIsFinance] = useState("");
    const [FinanceMemberNameOptions, setFinanceMemberNameOptions] = useState([]);
    const [FinanceMemberName, setFinanceMemberName] = useState("");
    const [ChangeFeasibilityAnnexed, setChangeFeasibilityAnnexed] = useState("");

    // 4th screen
    const [IsStopWork, setIsStopWork] = useState(false);
    const [StopWork, setStopWork] = useState("No");
    const [NoofDays, setNoofDays] = useState("");
    const [ChangeInitiationBy, setChangeInitiationBy] = useState("");
    const [ChangeInitiationOn, setChangeInitiationOn] = useState("");
    const [StopWorkApprovedByDMC, setStopWorkApprovedByDMC] = useState("");
    const [DMCMemberEmail, setDMCMemberEmail] = useState("");
    const [StopWorkApprovedByDmcOn, setStopWorkApprovedByDmcOn] = useState("");
    const [StopWorkApprovedByPda, setStopWorkApprovedByPda] = useState("");
    const [PDAStopWorkApprover, setPDAStopWorkApprover] = useState("");
    const [StopWorkApprovedByPdaOn, setStopWorkApprovedByPdaOn] = useState("");
    const [PdastopworkapprovalRemarks, setPdastopworkapprovalRemarks] = useState("");
    const [StopWorkApprovedByGC, setStopWorkApprovedByGC] = useState("");
    const [GCStopWorkApprover, setGCStopWorkApprover] = useState("");
    const [GCStopWorkApproveDate, setGCStopWorkApproveDate] = useState("");
    const [GCstopworkapprovalRemarks, setGCstopworkapprovalRemarks] = useState("");
    const [ApprovedByDmc, setApprovedByDmc] = useState("");
    const [ApprovedByDmcDate, setApprovedByDmcDate] = useState("");
    const [RemarksforRejection, setRemarksforRejection] = useState("");
    const [ApprovedByPDATeam, setApprovedByPDATeam] = useState("");
    const [RemarkByPDA, setRemarkByPDA] = useState("");
    const [ApprovedByPDATeamOn, setApprovedByPDATeamOn] = useState("");
    const [ApprovedByFinance, setApprovedByFinance] = useState("");
    const [FinanceApprover, setFinanceApprover] = useState("");
    const [ApprovedByFinanceOn, setApprovedByFinanceOn] = useState("");
    const [RemarkByFinance, setRemarkByFinance] = useState("");
    const [ChangeAcceptedbyDMC, setChangeAcceptedbyDMC] = useState("");
    const [RemarkByProjDmc, setRemarkByProjDmc] = useState("");
    const [ProjDMCAcceptanceDate, setProjDMCAcceptanceDate] = useState("");
    const [ChangeValidatedByPDA, setChangeValidatedByPDA] = useState("");
    const [PDAApprover, setPDAApprover] = useState("");
    const [RemarkByPdaHead, setRemarkByPdaHead] = useState("");
    const [ApprovedByPdaOn, setApprovedByPdaOn] = useState("");
    const [ChangeApprovedbyGC, setChangeApprovedbyGC] = useState("");
    const [GCApprover, setGCApprover] = useState("");
    const [RemarkByBH, setRemarkByBH] = useState("");
    const [GCHeadApprovalDate, setGCHeadApprovalDate] = useState("");
    const [IsStopWorkPendingWithInitiator, setIsStopWorkPendingWithInitiator] = useState("");

    //storeEmailId
    const [PDASpocEmail, setPDASpocEmail] = useState("");
    const [FinanceMemberEmail, setFinanceMemberEmail] = useState("");


    //attachment
    const [UploadedFileLocation, setUploadedFileLocation] = useState("");
    const [MultipleFileLocations, setMultipleFileLocations] = useState([]);
    const [FileNames, setFileNames] = useState([]);

    const [newAttachedFile, setnewAttachedFile] = useState([]);
    const [fileList, setFileList] = useState([]);

    const [CT, setCT] = useState("");
    const [NT, setNT] = useState("");
    const [PT, setPT] = useState("");

    let username = localStorage.getItem('UserName');
    let useremail = localStorage.getItem('UserId');

    let tmpVal = true;
    let tmpstopwork = false;
    let Modified = "";
    let FileName = "";
    let FileLocations = "";
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();
    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;
    const todaysdate = dd + '/' + mm + '/' + yyyy;


    let PrevTabEvent = "";

    const handleChangeStatus = ({ meta, remove }, status) => {
        console.log(status, meta);
    }


    const onFileDrop = (e) => {

        const newFile = e.target.files[0];

        const abc = [...newAttachedFile, newFile];
        let tmpAllowedFileType = process.env.REACT_APP_ATTACHMENT_TYPE;
        const AllowedFileType = tmpAllowedFileType.split(",");
        let isvalid = true;
        let isvalidFormat = false;
        let Filetype = "";

        Filetype = newFile.name.slice((Math.max(0, newFile.name.lastIndexOf(".")) || Infinity) + 1)

        for (let j = 0; j < AllowedFileType.length; j++) {
            if (Filetype == AllowedFileType[j]) {
                isvalidFormat = true;
            }
        }

        if (isvalidFormat != true) {
            alert("Attached file should be of type .doc, .pdf, .xls, .xlsx, .mst, .ppt, .dwg \r\n Files are not saved ");
            isvalidFormat = false;
            return;
        }
        if (newFile.size > 5000000) {
            alert("File " + newFile.name + " is greater than 5 MB \r\n Files are not saved");
            isvalid = false;
            return;
        }

        if (isvalid) {
            if (newFile) {
                const updatedList = [...fileList, newFile];
                setFileList(updatedList);
                handleAttachmentSubmit(abc);

                setnewAttachedFile([]);
            }
        }
    };

    const fileRemove = (file) => {
        const updatedList = [...fileList];
        updatedList.splice(fileList.indexOf(file), 1);
        setFileList(updatedList);
        MultipleFileLocations.splice(fileList.indexOf(file), 1);
        FileNames.splice(fileList.indexOf(file), 1);

    };

    const handleAttachmentSubmit = async (files) => {

        let isvalid = true;

        let tmpAllowedFileType = process.env.REACT_APP_ATTACHMENT_TYPE;
        const AllowedFileType = tmpAllowedFileType.split(",");

        for (let i = 0; i < files.length; i++) {
            let isvalidFormat = false;
            const tempf = files[i];

            let Filetype = "";
            Filetype = files[i].name.slice((Math.max(0, files[i].name.lastIndexOf(".")) || Infinity) + 1)

            for (let j = 0; j < AllowedFileType.length; j++) {
                if (Filetype == AllowedFileType[j]) {
                    isvalidFormat = true;
                }
            }

            if (isvalidFormat != true) {
                alert("Attached file should be of type .doc, .pdf, .xls, .xlsx, .mst, .ppt, .dwg \r\n Files are not saved ");
                isvalidFormat = false;
                return;
            }
            if (files[i].size > 5000000) {
                alert("File " + files[i].name + " is greater than 5 MB \r\n Files are not saved");
                isvalid = false;
                return;
            }

        }
        if (isvalid == true) {

            for (let i = 0; i < files.length; i++) {
                const f = files[i];
                FileNames.push({ Fnames: files[i].name });
                const API_ENDPOINT = process.env.REACT_APP_CHANGEINITIATION_API_URL + "Attachment/filename/" + files[i].name

                const response = await axios({
                    method: 'GET',
                    url: API_ENDPOINT
                }).catch((error) => {
                    console.log(error);

                });


                const result = await fetch(response.data.uploadURL, {
                    method: 'PUT',
                    body: files[i]
                }).catch((error) => {
                    console.log(error);

                });

                let var2 = response.data.uploadURL;
                // let s3Location = var2.substring(0, var2.indexOf("?"));
                let s3Location = response.data.uploadURL;

                setUploadedFileLocation(s3Location);
                MultipleFileLocations.push({ s3Location: s3Location });


            }

        }



    }
    const onBackClick = () => {
        var NavPath = sessionStorage.getItem("NavPath");
        navigate(NavPath);
    }

    const onSwitchAction = () => {
        if (document.getElementById("stopwork").checked == true) {
            setStopWork("Yes");
        }
        else {
            setStopWork("No");
            setNoofDays("");
        }
    };
    let MaxRevNo = 0;
    const fetchworktype = async () => {

        setChangeFesibility_releventStakeholders("No");
        const result = await getWorktype();
        setWorkTypeoptions(result);

        setChangeRequestDate(todaysdate);
        setChangeInitiationOn(todaysdate);
        setStopWorkApprovedByDmcOn(todaysdate);
        setStopWorkApprovedByPdaOn(todaysdate);
        setGCStopWorkApproveDate(todaysdate);
        setApprovedByDmcDate(todaysdate);
        setApprovedByPDATeamOn(todaysdate);
        setApprovedByFinanceOn(todaysdate);
        setProjDMCAcceptanceDate(todaysdate);
        setApprovedByPdaOn(todaysdate);
        setGCHeadApprovalDate(todaysdate);
        setWorkType(document.getElementById("WorkType").value);
        Modified = todaysdate;
        document.getElementById("sectiontablerow").classList.add('invisible');
        fetchProjectdetails();
        setCT("ChangeInitiation");
        setNT("TimeAndCost");
        setPT("");

    };

    const fetchTeam = async () => {
        const result = await getTeams();
        setTeamOptions(result);
        setTeamName(document.getElementById("TeamName").value);
        fetchProjectdetails();
    };
    const fetchWorkPackage = async () => {
        const result = await getWorkPackage();
        setWorkPackageOptions(result);
        setWorkPackage(result[0].WorkPackageDetail);
    };

    const FetchUsetDetails = async () => {
        const result = await getEmpdetails(useremail);
        setChangeInitiationOwner((result.map(t => t.NameOfEmployee)).toString());
        setChangeInitiationOwnerEmail(useremail.toString());
        setChangeInitiationBy((result.map(t => t.NameOfEmployee)).toString());
        setDMCMember((result.map(t => t.L_Plus_1_Name)).toString());
        setDMCMemberEmail((result.map(t => t.L_Plus_1)).toString());
    };

    const fetchPDASpocName = async () => {
        document.getElementById("btnPdaInitiation").style.display = "none";
        const result = await getActivePdaSpocName("Yes");
        const resultPdaSpoc = await getPdaSpocName();
        let data = result.map(t => t.TeamMemberName);
        let dataEmail = resultPdaSpoc.map(t => t.TeamMemberEmail);
        let uniquedataEmail = [...new Set(dataEmail)];
        for (let property in uniquedataEmail) {
            if (useremail === uniquedataEmail[property]) {
                document.getElementById("btnPdaInitiation").style.display = "block";
            }
        }
        let uniquedata = [...new Set(data)];
        let PDASpocName = [];
        PDASpocName.push({ PDAname: "Select" });
        for (let property in uniquedata) {
            PDASpocName.push({ PDAname: uniquedata[property] });
        }
        setPDASpocNameOptions(PDASpocName);
        setPDASpocName(document.getElementById("pdaspoc").value);

    };
    const fetchFinanceTeam = async () => {
        const result = await getFinanceTeam();
        let data = result.map(t => t.FName);
        let uniquedata = [...new Set(data)];
        let FinanceName = [];
        FinanceName.push({ Fname: "Select" });
        for (let property in uniquedata) {
            FinanceName.push({ Fname: uniquedata[property] });
        }
        setFinanceMemberNameOptions(FinanceName);
        setFinanceMemberName(document.getElementById("financeteam").value);

    };

    const fetchConstructionSectionTeamOptions = async () => {

        const result = await getAll();
        let data = result.map(t => t.SMTeamName);
        let uniquedata = [...new Set(data)];
        let ConstructionSectionTeam = [];
        ConstructionSectionTeam.push({ SectionTeam: "Select", SectionTeamValue: "" });
        for (let property in uniquedata) {
            ConstructionSectionTeam.push({ SectionTeam: uniquedata[property], SectionTeamValue: uniquedata[property] });
        }
        setConstructionSectionTeamOptions(ConstructionSectionTeam);
        settmpGodrejConstructionSections(document.getElementById("constructionSectionTeam").value);
        fetchSectionEmailOptions();
    };
    const fetchSectionEmailOptions = async () => {

        const sectionTeam = document.getElementById("constructionSectionTeam").value;
        const result = await getSectionEmail(sectionTeam);
        let data = result.map(t => t.SMEmailID);
        let uniquedata = [...new Set(data)];
        let ConstructionSectionEmail = [];
        ConstructionSectionEmail.push({ SMEmailID: "Select", SMEmailIDValue: "" });
        for (let property in uniquedata) {
            ConstructionSectionEmail.push({ SMEmailID: uniquedata[property], SMEmailIDValue: uniquedata[property] });
        }
        setGodrejConstructionSectionsEmailOptions(ConstructionSectionEmail);
    };

    const FetchApprovalHeads = async () => {
        let alertMessage = "";
        const result = await getApprovalHeads();
        const PDAHead = result.filter(obj => {
            return obj.Title === "ChangeTimeLinePDA"
        })
        console.log("PDAHead")
        console.log(PDAHead)
        if ((PDAHead.map(i => i.ApproverName)).toString() == "" || (PDAHead.map(i => i.ApproverName)).toString() == null || (PDAHead.map(i => i.ApproverName)).toString() == undefined) {
            alertMessage += "PDA Head name is not maintained in master \r\n";
        }
        if ((PDAHead.map(i => i.ApproverEmailID)).toString() == "" || (PDAHead.map(i => i.ApproverEmailID)).toString() == null || (PDAHead.map(i => i.ApproverEmailID)).toString() == undefined) {
            alertMessage += "PDA Head Email is not maintained in master \r\n";
        }
        setPDAStopWorkApprover((PDAHead.map(i => i.ApproverEmailID)).toString());
        setPDAApprover((PDAHead.map(i => i.ApproverName)).toString());
        const BHHead = result.filter(obj => {
            return obj.Title === "GCBusinessHead"
        })
        if ((BHHead.map(i => i.ApproverName)).toString() == "" || (BHHead.map(i => i.ApproverName)).toString() == null || (BHHead.map(i => i.ApproverName)).toString() == undefined) {
            alertMessage += "Business Head name is not maintained in master \r\n";
        }
        if ((BHHead.map(i => i.ApproverEmailID)).toString() == "" || (BHHead.map(i => i.ApproverEmailID)).toString() == null || (BHHead.map(i => i.ApproverEmailID)).toString() == undefined) {
            alertMessage += "Business Head Email is not maintained in master \r\n";
        }
        setGCStopWorkApprover((BHHead.map(i => i.ApproverEmailID)).toString());
        setGCApprover((BHHead.map(i => i.ApproverName)).toString());
        if (alertMessage != "") {
            alert(alertMessage);
            navigate("/Raised");
        }
    };

    const fetchOtherDivisionsOptions = async () => {

        const result = await getOtherDiv();
        let data = result.map(t => t.Division);

        let uniquedata = [...new Set(data)];
        let tempOtherDivision = [];
        tempOtherDivision.push({ Division: "Select" });
        for (let property in uniquedata) {
            tempOtherDivision.push({ Division: uniquedata[property] });
        }
        setOtherDivisionOptions(tempOtherDivision);
        setOtherDivision(document.getElementById("OtherDivision").value);
        fetchOtherDivisionEmailOptions();
    };

    const fetchOtherDivisionEmailOptions = async () => {

        const other = document.getElementById("OtherDivision").value;
        const result = await getOtherDivEmail(other);
        let data = result.map(t => t.EmailId);
        let uniquedata = [...new Set(data)];
        let OtherEmail = [];
        OtherEmail.push({ EmailId: "Select" });
        for (let property in uniquedata) {
            OtherEmail.push({ EmailId: uniquedata[property] });
        }
        setOtherDivisionEmailOptions(OtherEmail);
    };

    const fetchProjectdetails = async () => {
        const WorkType = document.getElementById("WorkType").value;
        const TeamName = document.getElementById("TeamName").value;
        const result = await getProject(TeamName, WorkType);
        setProjectNameOptions1(result);
        setProjectdetails(result);
        setProjectNumber(document.getElementById("ProjectNumber").value);
        setProjectName(document.getElementById("ProjectName").value);
        setProjectOwner(document.getElementById("ProjectOwner").value);
        setDMCMemberOwner(document.getElementById("DMCMemberOwner").value);
        fetchSelectedProjectData();

    };
    const fetchProjectData = async () => {
        const WorkType = document.getElementById("WorkType").value;
        const TeamName = document.getElementById("TeamName").value;
        const ProjName = document.getElementById("ProjectName").value;
        const result = await getProjectDetails(TeamName, WorkType, ProjName);
        setProjectdetails(result);
        setProjectNumber(document.getElementById("ProjectNumber").value);
        setProjectName(document.getElementById("ProjectName").value);
        setProjectOwner(document.getElementById("ProjectOwner").value);
        setDMCMemberOwner(document.getElementById("DMCMemberOwner").value);
        fetchSelectedProjectData();
    };
    const fetchSelectedProjectData = async () => {

        const projnum = document.getElementById("ProjectNumber").value;
        const revisionNoresult = await getSelectedProjectRevisionNo(projnum);

        let data = revisionNoresult.map(t => t.RevisionNo);

        MaxRevNo = Math.max.apply(null, data);


        if (isFinite(MaxRevNo) && MaxRevNo != 0) {
            setChangeRequestNumber(projnum + "-" + (Math.floor(MaxRevNo) + 1));
            setRevisionNo(Math.floor(MaxRevNo) + 1);
            setMaxRevisionNo(MaxRevNo);
            // setWorkPackage(WorkPackageOptions[0].WorkPackageDetail);

            const TimeandCostResult = await getSelectedProjectTimeandCost(projnum, MaxRevNo);

            setOriginalchangenoAndDate((TimeandCostResult.map(i => i.OriginalchangenoAndDate)).toString());
            setOriginal_time(((TimeandCostResult.map(i => i.Original_time)).toString()));
            setOriginal_Cost(((TimeandCostResult.map(i => i.Original_Cost)).toString()));
            setR1_ChangeNoAndDate(((TimeandCostResult.map(i => i.R1_ChangeNoAndDate)).toString()));
            setR1_Time(((TimeandCostResult.map(i => i.R1_Time)).toString()));
            setR1_Cost(((TimeandCostResult.map(i => i.R1_Cost)).toString()));
            setR2_ChangeNoAndDate(((TimeandCostResult.map(i => i.R2_ChangeNoAndDate)).toString()));
            setR2_Time(((TimeandCostResult.map(i => i.R2_Time)).toString()));
            setR2_Cost(((TimeandCostResult.map(i => i.R2_Cost)).toString()));
            setR3_ChangeNoAndDate(((TimeandCostResult.map(i => i.R3_ChangeNoAndDate)).toString()));
            setR3_Time(((TimeandCostResult.map(i => i.R3_Time)).toString()));

            setR3_Cost(((TimeandCostResult.map(i => i.R3_Cost)).toString()));

            setR4_ChangeNoAndDate(((TimeandCostResult.map(i => i.R4_ChangeNoAndDate)).toString()));

            setR4_Time(((TimeandCostResult.map(i => i.R4_Time)).toString()));

            setR4_Cost(((TimeandCostResult.map(i => i.R4_Cost)).toString()));

            if (MaxRevNo == 0) {
                setR1_ChangeNoAndDate(projnum + "-" + (MaxRevNo + 1) + "-" + ChangeRequestDate);
            } else if (MaxRevNo == 1) {
                setR2_ChangeNoAndDate(projnum + "-" + (MaxRevNo + 1) + "-" + ChangeRequestDate);
            } else if (MaxRevNo == 2) {
                setR3_ChangeNoAndDate(projnum + "-" + (MaxRevNo + 1) + "-" + ChangeRequestDate);
            }
            else if (MaxRevNo == 3) {
                setR4_ChangeNoAndDate(projnum + "-" + (MaxRevNo + 1) + "-" + ChangeRequestDate);
            }

        }
        else {
            setChangeRequestNumber((projnum + "-" + 1).toString());
            setMaxRevisionNo(0);
            setRevisionNo(1);
            setOriginalchangenoAndDate("");
            setOriginal_time("");
            setOriginal_Cost("");
            setR1_ChangeNoAndDate((projnum + "-" + (1) + "-" + ChangeRequestDate).toString());
            setR1_Time("");
            setR1_Cost("");
            setR2_ChangeNoAndDate("");
            setR2_Time("");
            setR2_Cost("");
            setR3_ChangeNoAndDate("");
            setR3_Time("");
            setR3_Cost("");
            setR4_ChangeNoAndDate("");
            setR4_Time("");
            setR4_Cost("");
        }
    }

    const getFinanceMemberEmail = async () => {
        const Fname = document.getElementById("financeteam").value;
        const result = await getFinanceEmail(Fname);
        setFinanceMemberEmail((result.map(t => t.FEmail)).toString())
    }
    const getPDASpocEmail = async () => {
        const name = document.getElementById("pdaspoc").value;
        const result = await getEmailId(name);
        setPDASpocEmail((result.map(t => t.TeamMemberEmail)).toString())
    }


    const YesNoOptions = [
        { value: '', label: 'Select' },
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }
    ];


    const btnCancelClick = async (e) => {
        navigate("/Raised");
    }
    const validateNumber = async (tmpcost, tmpid) => {
        var t = "";
        t = ((tmpcost.indexOf(".") >= 0) ? (tmpcost.substr(0, tmpcost.indexOf(".")) + tmpcost.substr(tmpcost.indexOf("."), 3)) : tmpcost);
        switch (tmpid) {
            case "Original_Cost":
                setOriginal_Cost(t);
                break;
            case "R1_Cost":
                setR1_Cost(t);
                break;
            case "R2_Cost":
                setR2_Cost(t);
                break;
            case "R3_Cost":
                setR3_Cost(t);
                break;
            case "R4_Cost":
                setR4_Cost(t);
                break;
            default:
                return
        }
    }

    const validateNumberofDays = async (tmpNoofDays) => {

        setNoofDays(tmpNoofDays.split(".")[0])

        if (Math.sign(tmpNoofDays) == -1) {
            alert("Please Enter Positive value of  No of Days");
            setNoofDays("");
        }

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setProjectName(document.getElementById("ProjectName").value);
        setProjectNumber(document.getElementById("ProjectNumber").value);
        setDMCMemberOwner(document.getElementById("DMCMemberOwner").value);
        setTeamName(document.getElementById("TeamName").value);
        if (ProjectName.trim() == "") {
            alert("Please Select ProjectName");
            return;
        }

        const FileLocations = MultipleFileLocations.map(i => i.s3Location).toString();
        const FileName = FileNames.map(i => i.Fnames).toString();

        if ((document.getElementById("ChangeFesibility_releventStakeholders").value).toUpperCase() != "YES") {
            setChangeFesibility_releventStakeholders("No");
            setSelectedConstructionSection([]);
        }

        const GodrejConstructionSections = SelectedConstructionSection.map(i => i.SectionEmail).toString();
        const GodrejSectionDivisions = SelectedConstructionSection.map(i => i.SectionTeam).toString();

        const CountOfStakeHolders = SelectedConstructionSection.length;
        setFeasibilityReportCount(0);

        setChangeFeasibilityAnnexed("Yes");
        if ((document.getElementById("OtherDivision").value).toUpperCase() != "YES") {
            setOther("");
            setOtherDivision("");
        }
        else {
            setOther(OtherDivisionEmail.toString());
        }


        setPDASpocName(document.getElementById("pdaspoc").value);

        if ((document.getElementById("IsFinance").value).toUpperCase() != "YES") {
            setFinanceMemberName("");
            setIsFinance("No");
        }
        else {
            setFinanceMemberName(document.getElementById("financeteam").value);
        }

        if ((document.getElementById("ChangeFeasibilityAnnexed").value).toUpperCase() != "YES") {
            setChangeFeasibilityAnnexed("No");
        }

        const Created = new Date().getDate() + '-' + (new Date().getMonth() + 1) + '-' + new Date().getFullYear();
        const CreatedBy = ChangeInitiationOwner.toString();
        const PrevChangeReqNoData = await getdetails(ChangeRequestNumber);


        const ChangeInitiation = {
            WorkType, TeamName, ProjectName, ProjectNumber, ProjectOwner, DMCMemberOwner
            , ChangeInitiationOwner, ChangeInitiationOwnerEmail, ChangeRequestDate, ChangeRequestNumber
            , DMCMember, DMCMemberEmail, WorkPackage, Existing, Proposed
            , JustificationOfChange
            , AssumptionsConstraints
            , KeyRisks
            , OriginalchangenoAndDate
            , Original_time
            , Original_Cost
            , R1_ChangeNoAndDate
            , R1_Time
            , R1_Cost
            , R2_ChangeNoAndDate
            , R2_Time
            , R2_Cost
            , R3_ChangeNoAndDate
            , R3_Time
            , R3_Cost
            , R4_ChangeNoAndDate
            , R4_Time
            , R4_Cost

            , ChangeFesibility_releventStakeholders
            , GodrejConstructionSections
            , GodrejSectionDivisions
            , OtherDivision
            , OtherDivisionEmail
            , Other
            , ChangeFeasibilityAnnexed

            , ChangeInitiationBy
            , ApprovedByDmc
            , ChangeInitiationOn

            , ApprovedByPDATeam
            , ChangeAcceptedbyDMC
            , ChangeValidatedByPDA
            , ChangeApprovedbyGC
            , NoofDays
            , PDAStopWorkApprover
            , StopWorkApprovedByPda
            , StopWorkApprovedByGC
            , StopWorkApprovedByDMC
            , PDAApprover
            , RemarkByPdaHead
            , GCApprover
            , GCStopWorkApprover
            , CountOfStakeHolders
            , FeasibilityReportCount
            , StopWork
            , RevisionNo
            , Created
            , CreatedBy
            , Modified
            , PDASpocName
            , PDASpocEmail
            , RemarkByPDA
            , IsFinance
            , FinanceMemberName
            , FinanceMemberEmail
            , StopWorkApprovedByDMC
            , StopWorkApprovedByDmcOn
            , StopWorkApprovedByPdaOn
            , GCStopWorkApproveDate
            , ApprovedByDmcDate
            , ApprovedByPDATeamOn
            , ApprovedByFinanceOn
            , ApprovedByFinance
            , ProjDMCAcceptanceDate
            , ApprovedByPdaOn
            , GCHeadApprovalDate
            , IsStopWorkPendingWithInitiator
            , FileLocations
            , FileName
            , OriginalCommitment_Time, OriginalCommitmentCost, LastApproved_Time, LastApprovedCost, NewProposed_time,
            NewProposed_Cost, Impact_EndDate, Impact_Budget, RevisedBudget, RevisedEndDate
        };
        var alertMessage = "";

        if (ProjectName.trim() == "") { alertMessage += "Please Select Project Name \r\n"; }
        if (WorkType.trim() == "") { alertMessage += "Please Select Work Type \r\n"; }
        if (TeamName.trim() == "") { alertMessage += "Please Select Team Name \r\n"; }
        if (ProjectNumber.trim() == "") { alertMessage += "Project Number is Empty \r\n"; }
        if (DMCMemberOwner.trim() == "") { alertMessage += "Please Select Project DMC \r\n"; }
        if (ProjectOwner.trim() == "") { alertMessage += "Project Owner Empty\r\n"; }
        if (ChangeInitiationOwner.trim() == "") { alertMessage += "Name of Change initiation owner in empty \r\n"; }
        if (DMCMember.trim() == "") { alertMessage += "DMC Member (Seeking Change) is Empty\r\n"; }
        if (ChangeRequestDate.trim() == "") { alertMessage += "Request Date is Empty\r\n"; }
        if (ChangeRequestNumber.trim() == "") { alertMessage += "Change Request Number is Empty\r\n"; }



        if (WorkPackage.trim() == "") { alertMessage += "Please Select Work Package \r\n"; }
        if (Existing.trim() == "") { alertMessage += "Please Enter Existing Field\r\n"; }

        if (Proposed.trim() == "") { alertMessage += "Please Enter Proposed Field \r\n"; }
        if (JustificationOfChange.trim() == "") { alertMessage += "Please Enter Justification of Change field \r\n"; }
        if (AssumptionsConstraints.trim() == "") { alertMessage += "Please Enter Assumptions Constraints field \r\n"; }

        if (MaxRevisionNo == 0 && Original_time.trim() == "") { alertMessage += "Please Enter Original Time \r\n"; }

        if (MaxRevisionNo == 0 && Original_Cost.trim() == "") { alertMessage += "Please Enter Original Cost \r\n"; }

        if (MaxRevisionNo == 0 && R1_Time.trim() == "") { alertMessage += "Please Enter R1 Time \r\n"; }

        if (MaxRevisionNo == 0 && R1_Cost.trim() == "") { alertMessage += "Please Enter R1 Cost \r\n"; }
        if (MaxRevisionNo == 1 && R2_Time.trim() == "") { alertMessage += "Please Enter R2 Time \r\n"; }

        if (MaxRevisionNo == 1 && R2_Cost.trim() == "") { alertMessage += "Please Enter R2 Cost \r\n"; }
        if (MaxRevisionNo == 2 && R3_Time.trim() == "") { alertMessage += "Please Enter R3 Time \r\n"; }

        if (MaxRevisionNo == 2 && R3_Cost.trim() == "") { alertMessage += "Please Enter R3 Cost \r\n"; }
        if (MaxRevisionNo == 3 && R4_Time.trim() == "") { alertMessage += "Please Enter R4 Time \r\n"; }

        if (MaxRevisionNo == 3 && R4_Cost.trim() == "") { alertMessage += "Please Enter R4 Cost \r\n"; }

        if (ChangeFesibility_releventStakeholders.trim() == "") { alertMessage += "Please Select Change Fesibility Relevent Stakeholders \r\n"; }
        if (ChangeFesibility_releventStakeholders.trim().toUpperCase() == "YES" && GodrejSectionDivisions == "") { alertMessage += "Please Select Godrej Construction Divisions\r\n "; }
        if (ChangeFesibility_releventStakeholders.trim().toUpperCase() == "YES" && GodrejSectionDivisions.trim != "" && GodrejConstructionSections == "") { alertMessage += "Please Select Godrej Construction Sections\r\n "; }
        if (PDASpocName.trim() == "" || PDASpocName.trim().toUpperCase() == "SELECT") { alertMessage += "Please Select PDASpoc Name\r\n "; }
        if (IsFinance.trim() == "") { alertMessage += "Please Select Send To Finance\r\n "; }
        if (IsFinance.trim().toUpperCase() == "YES" && (FinanceMemberName.trim().toUpperCase() == "SELECT" || FinanceMemberName.trim().toUpperCase() == "")) { alertMessage += "Please Select Finance Member Name\r\n "; }
        if (GCApprover == "" || GCApprover == null || GCApprover == undefined) { alertMessage += "Business Head Name is Empty\r\n "; }
        if (PDAApprover == "" || PDAApprover == null || PDAApprover == undefined) { alertMessage += "PDA Head Name is Empty\ r\n "; }
        if (GCStopWorkApprover == "" || GCStopWorkApprover == null || GCStopWorkApprover == undefined) { alertMessage += "Business Head Name for Stop Work Approval is Empty\r\n "; }
        if (PDAStopWorkApprover == "" || PDAStopWorkApprover == null || PDAStopWorkApprover == undefined) { alertMessage += "PDA Head Name for Stop Work Approval is Empty\r\n "; }
        if (DMCMemberEmail == "" || DMCMemberEmail == null || DMCMemberEmail == undefined) { alertMessage += "Please Enter PDA Head Name\r\n "; }
        if (StopWork.trim().toUpperCase() == "YES" && (NoofDays.trim() == "")) { alertMessage += "Please Enter No of Days\r\n "; }

        if (alertMessage != "") {
            alert(alertMessage);
            return;
        }


        if (PrevChangeReqNoData.length === 0) {
            EnrollNewChangeInitiation(ChangeInitiation)
                .then((resp) => {

                    // if (resp.data.status) {
                    navigate('/Raised', { replace: true });
                    alert("Change Initiation Request Number " + ChangeRequestNumber + " Inserted Successfully");


                    // }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
        else {
            navigate('/Raised', { replace: true });
            alert("Change Request for Change Request Number " + ChangeRequestNumber + " is already submitted ");

            // navigate("/Raised");
        }
    };



    const getProjectOptions = () => {
        fetchProjectdetails();
    };
    const getSectionEmailOptions = () => {
        fetchSectionEmailOptions();
    };
    const getOtherDivisionEmailOptions = () => {
        fetchOtherDivisionEmailOptions();
    };


    const chkValdate = (tmpDate, tmpControl) => {

        const dateObjtmp1 = todaysdate.split(" ")[0].split("/");
        const dateObj1 = new Date(dateObjtmp1[2] + "-" + dateObjtmp1[1] + "-" + dateObjtmp1[0]);
        const dateObj2 = new Date(tmpDate);
        // if (dateObj1 > dateObj2) {
        // alert("Selected date is less than todays date")
        switch (tmpControl) {
            case "Original_time":
                if (dateObj1 > dateObj2) {
                    alert("Selected date is less than todays date")
                    setOriginal_time("");
                    break;
                }
                if (R1_Time != "") {
                    const dateObj3 = new Date(R1_Time);
                    if (dateObj2 > dateObj3) {
                        alert("Original Time should not greater than R1 Time")
                        setOriginal_time("");
                    }
                }
                break;
            case "R1_Time":
                if (dateObj1 > dateObj2) {
                    alert("Selected date is less than todays date")
                    setR1_Time("");
                    break;
                }
                if (Original_time != "") {
                    const dateObj3 = new Date(Original_time);
                    if (dateObj2 < dateObj3) {
                        alert("R1 Time should not less than Original Time")
                        setR1_Time("");
                        break
                    }
                }
                if (R2_Time != "") {
                    const dateObj3 = new Date(R2_Time);
                    if (dateObj2 > dateObj3) {
                        alert("R1 Time should not greater than R2 Time")
                        setR1_Time("");
                    }
                }

                break;
            case "R2_Time":
                if (dateObj1 > dateObj2) {
                    alert("Selected date is less than todays date")
                    setR2_Time("");
                    break;
                }
                if (R1_Time != "") {
                    const dateObj3 = new Date(R1_Time);
                    if (dateObj2 < dateObj3) {
                        alert("R2 Time should not less than R1 Time")
                        setR2_Time("");
                        break
                    }
                }
                if (R3_Time != "") {
                    const dateObj3 = new Date(R3_Time);
                    if (dateObj2 > dateObj3) {
                        alert("R2 Time should not greater than R3 Time")
                        setR2_Time("");
                    }
                }
                break;
            case "R3_Time":
                if (dateObj1 > dateObj2) {
                    alert("Selected date is less than todays date")
                    setR3_Time("");
                    break;
                }
                if (R2_Time != "") {
                    const dateObj3 = new Date(R2_Time);
                    if (dateObj2 < dateObj3) {
                        alert("R3 Time should not less than R2 Time")
                        setR3_Time("");
                        break
                    }
                }
                if (R4_Time != "") {
                    const dateObj3 = new Date(R4_Time);
                    if (dateObj2 > dateObj3) {
                        alert("R3 Time should not greater than R4 Time")
                        setR3_Time("");
                    }
                }
                break;
            case "R4_Time":
                if (dateObj1 > dateObj2) {
                    alert("Selected date is less than todays date")
                    setR4_Time("");
                    break;
                }
                if (R3_Time != "") {
                    const dateObj3 = new Date(R3_Time);
                    if (dateObj3 > dateObj2) {
                        alert("R4 Time should not less than R3 Time")
                        setR4_Time("");
                    }
                }
                break;
            default:
                return
        }
        // }



    };

    // const chkValdate = (tmpDate, tmpControl) => {

    //     const dateObjtmp1 = todaysdate.split(" ")[0].split("/");
    //     const dateObj1 = new Date(dateObjtmp1[2] + "-" + dateObjtmp1[1] + "-" + dateObjtmp1[0]);
    //     const dateObj2 = new Date(tmpDate);
    //     if (dateObj1 > dateObj2) {
    //         alert("Selected date is less than todays date")
    //         switch (tmpControl) {
    //             case "Original_time":
    //                 setOriginal_time("");
    //                 break;
    //             case "R1_Time":
    //                 setR1_Time("");
    //                 break;
    //             case "R2_Time":
    //                 setR2_Time("");
    //                 break;
    //             case "R3_Time":
    //                 setR3_Time("");
    //                 break;
    //             case "R4_Time":
    //                 setR4_Time("");
    //                 break;
    //             default:
    //                 return
    //         }
    //     }



    // };

    const addsectionArray = (email) => {
        if (email != "" && email.toUpperCase() != "SELECT") {
            const selectedTeam = document.getElementById("constructionSectionTeam").value;
            let dataPresent = "";
            const result = SelectedConstructionSection.filter(obj => {
                return obj.SectionTeam === selectedTeam && obj.SectionEmail === email
            });
            if (result.length == 0) {
                SelectedConstructionSection.push({ SectionTeam: selectedTeam, SectionEmail: email });
            }
            setSelectedConstructionSection([...SelectedConstructionSection]);
        }

    };
    const removesectionArray = (email) => {

        var index = SelectedConstructionSection.map(function (o) { return o.SectionEmail; }).indexOf(email);

        SelectedConstructionSection.splice(index, 1);
        setSelectedConstructionSection([...SelectedConstructionSection])
        if (SelectedConstructionSection.length == 0) {
            settmpGodrejConstructionSections("Select");
            settmpGodrejConstructionSectionsEmail("Select");
        }
    };
    const chanegEvent = (e) => {
        switch (CT) {
            case "ChangeInitiation":
                setProjectName(document.getElementById("ProjectName").value);
                setProjectNumber(document.getElementById("ProjectNumber").value);
                setDMCMemberOwner(document.getElementById("DMCMemberOwner").value);
                setTeamName(document.getElementById("TeamName").value);
                var alertMessage = "";
                if (ProjectName.trim() == "") { alertMessage += "Please Select Project Name \r\n"; }
                if (WorkType.trim() == "") { alertMessage += "Please Select Work Type \r\n"; }
                if (TeamName.trim() == "") { alertMessage += "Please Select Team Name \r\n"; }
                if (ProjectNumber.trim() == "") { alertMessage += "Project Number is Empty \r\n"; }
                if (DMCMemberOwner.trim() == "") { alertMessage += "Project DMC is Empty\r\n"; }
                if (ProjectOwner.trim() == "") { alertMessage += "Project Owner Empty\r\n"; }


                if (ChangeInitiationOwner.trim() == "") { alertMessage += "Name of Change initiation owner in empty \r\n"; }
                if (DMCMember.trim() == "") { alertMessage += "DMC Member (Seeking Change) is Empty\r\n"; }
                if (ChangeRequestDate.trim() == "") { alertMessage += "Request Date is Empty\r\n"; }
                if (ChangeRequestNumber.trim() == "") { alertMessage += "Change Request Number is Empty\r\n"; }

                // if(ChangeRequestNumber.split("-").pop()>4) { alertMessage += "You Can Not Add More Than 4 Revisions \r\n"; }   

                if (WorkPackage.trim() == "") { alertMessage += "Please Select Work Package \r\n"; }
                if (Existing.trim() == "") { alertMessage += "Please Enter Existing Field \r\n"; }

                if (Proposed.trim() == "") { alertMessage += "Please Enter Proposed Field \r\n"; }
                if (JustificationOfChange.trim() == "") { alertMessage += "Please Enter Justification of Change Field \r\n"; }
                if (AssumptionsConstraints.trim() == "") { alertMessage += "Please Enter Assumptions Constraints Field \r\n"; }
                if (KeyRisks.trim() == "") { alertMessage += "Please Enter Key Risks Field \r\n"; }
                if (alertMessage.trim() != "") {
                    alert(alertMessage);
                    SetEventTab(CT);
                    return;
                }
                else {
                    SetEventTab(e);
                    setCT(e);
                    setNT("ChangeApproval1");
                    setPT("ChangeInitiation")
                }

                break;
            case "TimeAndCost":

                var alertMessage = "";
                if (MaxRevisionNo == 0 && Original_time.trim() == "") { alertMessage += "Please Enter Original Time \r\n"; }

                if (MaxRevisionNo == 0 && Original_Cost.trim() == "") { alertMessage += "Please Enter Original Cost \r\n"; }

                if (MaxRevisionNo == 0 && R1_ChangeNoAndDate.trim() == "") { alertMessage += "R1 Change number and date is Empty\r\n"; }
                if (MaxRevisionNo == 0 && R1_Time.trim() == "") { alertMessage += "Please Enter R1 Time \r\n"; }
                if (MaxRevisionNo == 0 && R1_Time.trim() == "") { alertMessage += "Please Enter R1 Time \r\n"; }
                if (MaxRevisionNo == 0 && R1_Cost.trim() == "") { alertMessage += "Please Enter R1 Cost \r\n"; }
                if (Math.sign(R1_Cost) == -1) { alertMessage += "Please Enter Positive value of R1 Cost \r\n"; }
                if (MaxRevisionNo == 1 && R2_ChangeNoAndDate.trim() == "") { alertMessage += "R2 Change number and date is Empty \r\n"; }
                if (MaxRevisionNo == 1 && R2_Time.trim() == "") { alertMessage += "Please Enter R2 Time \r\n"; }
                if (MaxRevisionNo == 1 && R2_Cost.trim() == "") { alertMessage += "Please Enter R2 Cost \r\n"; }
                if (Math.sign(R2_Cost) == -1) { alertMessage += "Please Enter Positive value of R2 Cost \r\n"; }
                if (MaxRevisionNo == 2 && R3_ChangeNoAndDate.trim() == "") { alertMessage += "R3 Change number and date is Empty \r\n"; }
                if (MaxRevisionNo == 2 && R3_Time.trim() == "") { alertMessage += "Please Enter R3 Time \r\n"; }
                if (MaxRevisionNo == 2 && R3_Cost.trim() == "") { alertMessage += "Please Enter R3 Cost \r\n"; }
                if (Math.sign(R3_Cost) == -1) { alertMessage += "Please Enter Positive value of R3 Cost \r\n"; }
                if (MaxRevisionNo == 3 && R4_ChangeNoAndDate.trim() == "") { alertMessage += "R4 Change number and date is Empty \r\n"; }
                if (MaxRevisionNo == 3 && R4_Time.trim() == "") { alertMessage += "Please Enter R4 Time \r\n"; }
                if (MaxRevisionNo == 3 && R4_Cost.trim() == "") { alertMessage += "Please Enter R4 Cost \r\n"; }
                if (Math.sign(R4_Cost) == -1) { alertMessage += "Please Enter Positive value of R4 Cost \r\n"; }
                setOriginalCommitment_Time(Original_time);
                setOriginalCommitmentCost(Original_Cost);
                setLastApproved_Time(R4_Time != "" ? R3_Time : R3_Time != "" ? R2_Time : R2_Time != "" ? R1_Time : R1_Time != "" ? Original_time : "");
                setNewProposed_time(R4_Time != "" ? R4_Time : R3_Time != "" ? R3_Time : R2_Time != "" ? R2_Time : R1_Time != "" ? R1_Time : "");
                setLastApprovedCost(R4_Cost != "" ? R3_Cost : R3_Cost != "" ? R2_Cost : R2_Cost != "" ? R1_Cost : R1_Cost != "" ? Original_Cost : "");
                setNewProposed_Cost(R4_Cost != "" ? R4_Cost : R3_Cost != "" ? R3_Cost : R2_Cost != "" ? R2_Cost : R1_Cost != "" ? R1_Cost : "");

                if (alertMessage != "") {
                    alert(alertMessage);
                    SetEventTab(CT);
                    return;
                }
                else {
                    SetEventTab(e);
                    setCT(e);
                    setNT("ChangeApproval2");
                    setPT("TimeAndCost");
                }
                break;
            case "ChangeApproval1":
                if ((document.getElementById("ChangeFesibility_releventStakeholders").value).toUpperCase() != "YES") {
                    setChangeFesibility_releventStakeholders("No");
                    setSelectedConstructionSection([]);
                }
                const GodrejConstructionSections = SelectedConstructionSection.map(i => i.SectionEmail).toString();
                const GodrejSectionDivisions = SelectedConstructionSection.map(i => i.SectionTeam).toString();


                const CountOfStakeHolders = SelectedConstructionSection.length;
                setFeasibilityReportCount(0);

                setChangeFeasibilityAnnexed("Yes");



                setPDASpocName(document.getElementById("pdaspoc").value);

                if ((document.getElementById("IsFinance").value).toUpperCase() != "YES") {
                    setFinanceMemberName("");
                    setIsFinance("No");
                }
                else {
                    setFinanceMemberName(document.getElementById("financeteam").value);
                }

                if ((document.getElementById("ChangeFeasibilityAnnexed").value).toUpperCase() != "YES") {
                    setChangeFeasibilityAnnexed("No");
                }
                var alertMessage = "";
                if (ChangeFesibility_releventStakeholders.trim() == "") { alertMessage += "Please Select Change Fesibility Relevent Stakeholders \r\n"; }
                if (ChangeFesibility_releventStakeholders.trim().toUpperCase() == "YES" && GodrejSectionDivisions == "") { alertMessage += "Please Select Godrej Construction Divisions\r\n"; }
                if (ChangeFesibility_releventStakeholders.trim().toUpperCase() == "YES" && GodrejSectionDivisions.trim != "" && GodrejConstructionSections == "") { alertMessage += "Please Select Godrej Construction Sections\r\n"; }

                // if (OtherDivision.trim() == "" || OtherDivision.trim().toUpperCase() == "SELECT") { alertMessage += "Please Select Other G&B Divisions\r\n"; }

                // if ((OtherDivision.trim() != "" && OtherDivision.trim().toUpperCase() != "SELECT") && (OtherDivisionEmail.trim().toUpperCase() == "SELECT" || OtherDivisionEmail.trim().toUpperCase() == "")) { alertMessage += "Please Select Other Division Email\r\n"; }


                if (PDASpocName.trim() == "" || PDASpocName.trim().toUpperCase() == "SELECT") { alertMessage += "Please Select PDASpoc Name\r\n"; }
                if (IsFinance.trim() == "") { alertMessage += "Please Select Send To Finance\r\n "; }
                if (IsFinance.trim().toUpperCase() == "YES" && (FinanceMemberName.trim().toUpperCase() == "SELECT" || FinanceMemberName.trim().toUpperCase() == "")) { alertMessage += "Please Select Finance Member Name\r\n "; }
                if (alertMessage != "") {
                    alert(alertMessage);
                    SetEventTab(CT);
                    return;
                }
                else {
                    SetEventTab(e);
                    setCT(e);
                    setNT("ChangeApproval2")
                    setPT("ChangeApproval1")
                }
                break;
            case "ChangeApproval2":
                SetEventTab(e);
                setCT(e);
                setNT("ChangeApproval2")
                setPT("ChangeApproval1")

                break;
        }
    }
    const SetEventTab = (e) => {
        const arrTAb = ["ChangeInitiation", "TimeAndCost", "ChangeApproval1", "ChangeApproval2"]
        for (let i = 0; i < arrTAb.length; i++) {
            let tabid = "controlled-tab-example-tab-" + arrTAb[i];
            let tabpaneid = "controlled-tab-example-tabpane-" + arrTAb[i];

            if (arrTAb[i] == e) {
                document.getElementById(tabid).removeAttribute('tabIndex');
                document.getElementById(tabid).classList.add('active');
                document.getElementById(tabid).ariaSelected = true;
                document.getElementById(tabpaneid).classList.add('active');
                document.getElementById(tabpaneid).classList.add('show');
            }
            else {
                document.getElementById(tabid).classList.remove('active');
                document.getElementById(tabid).ariaSelected = false;
                document.getElementById(tabid).tabIndex = -1;
                document.getElementById(tabpaneid).classList.remove('active');
                document.getElementById(tabpaneid).classList.remove('show');
            }


        }

    };
    const getProjectData = () => {
        fetchProjectData();
    };

    const handleSelect = async (e) => {
        alert(e);
        // if (key === 1)
        //     this.setState({ heading: "Log in" })
        // else
        //     this.setState({ heading: "Sign up" })
    }

    const ChangeFeasibilityDrpdownChange = () => {
        if (document.getElementById("ChangeFesibility_releventStakeholders").value.toUpperCase() != "YES") {

            while (SelectedConstructionSection.length > 0) {
                SelectedConstructionSection.pop();
            }
            setSelectedConstructionSection([...SelectedConstructionSection])
            settmpGodrejConstructionSections("Select");
            settmpGodrejConstructionSectionsEmail("Select");
            document.getElementById("constructionSectionTeam").value = "Select";
            document.getElementById("constructionSectionEmail").value = "Select";


        }

    };

    const ChangeFinanceDropdown = () => {
        if (document.getElementById("IsFinance").value.toUpperCase() != "YES") {
            document.getElementById("financeteam").value = "";
            setFinanceMemberName("");
        }

    };

    return (
        <Container className="LogbookForm">

            <div id="btnPdaInitiation" class=" float-end m-1">
                <Button class="btn-primary"
                    style={{ float: "right" }}
                    size="sm"
                    onClick={() => {
                        navigate("/createbypda")
                    }}
                >
                    PDA Initiation
                </Button>
            </div>
            <div class="float-end m-1">
                <Button class="btn-primary"
                    style={{ float: "right" }}
                    size="sm"
                    onClick={onBackClick}
                >
                    Back
                </Button>

            </div>

            <br />
            <br />



            <h5> <span class="text-primary">Change Initiation </span>
                <div id="btnNext" class="invisible float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                    onClick={(e) => { chanegEvent(NT) }} > Next </Button> </div>

                <div id="btnPrev" class="invisible float-end m-1"> <Button class="btn-primary" style={{ float: "left" }} size="sm"
                    onClick={(e) => { chanegEvent(PT) }} > Prev </Button> </div></h5>



            <hr />

            <Form className="form" value="Form" onSubmit={handleSubmit}>

                <Tabs id="controlled-tab-example" className="mb-3" onSelect={(e) => { chanegEvent(e) }}>

                    {/* <Tab eventKey="PDAInitiation" disabled id="PDAInitiation" title="Initiation By PDA">
                <h5> <span class="text-primary">Time and Cost target</span></h5>
                <Row>
                    <p><span class="font-weight-light">*Enter approved values for prior changes(if applicable) and estimated values for present change</span></p>
                </Row>
                </Tab> */}
                    <Tab eventKey="ChangeInitiation" disabled id="ChangeInitiation" title="Change Initiation">
                        <Row>
                            <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                onClick={(e) => { chanegEvent("TimeAndCost") }} > Next </Button> </div>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label class="required-field"> Work Type (SI / MI-A / MI-B / DI-A / DI-B / OI-A / OI-B / Project):</Form.Label>
                                    <Form.Select aria-label="WorkType" name="WorkType" id="WorkType" value={WorkType} required onChange={(e) => { setWorkType(e.target.value); getProjectOptions() }}  >
                                        {WorkTypeOptions.map((item, index) => {
                                            return <option key={index} value={item.WorkType}>{item.WorkType}</option>
                                        })}
                                    </Form.Select>
                                </Form.Group></Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label class="required-field"> Teams</Form.Label>
                                    <Form.Select aria-label="Teams" name="TeamName" id="TeamName" value={TeamName} required onChange={(e) => { setTeamName(e.target.value); getProjectOptions() }} >
                                        {TeamNameOptions.map((item, index) => {
                                            return <option key={index} value={item.TeamName}>{item.TeamName}</option>
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label class="required-field"> Initiative / Project Name</Form.Label>
                                    <Form.Select aria-label="ProjectName" id="ProjectName" name="ProjectName" value={ProjectName} onChange={(e) => { setProjectName(e.target.value); getProjectData() }} >
                                        {ProjectNameOptions1.map((item, index) => {
                                            return <option key={index} value={item.ProjectName}>{item.ProjectName}</option>
                                        })}
                                    </Form.Select>
                                </Form.Group></Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label> Initiative / Project Number</Form.Label>
                                    <Form.Select aria-label="ProjectNumber" id="ProjectNumber" disabled name="ProjectNumber" value={ProjectNumber} onChange={(e) => setProjectNumber(e.target.value)} >
                                        {ProjectDetails.map((item) => {
                                            return <option value={item.ProjectId}>{item.ProjectId}</option>
                                        })}
                                    </Form.Select>
                                </Form.Group></Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label> Initiative / Project Owner</Form.Label>
                                    <Form.Select aria-label="ProjectOwner" id="ProjectOwner" disabled name="ProjectOwner" value={ProjectOwner} onBeforeInput={(e) => setProjectOwner(e.target.value)} onChange={(e) => setProjectOwner(e.target.value)} >
                                        {ProjectDetails.map((item, index) => {
                                            return <option key={index} value={item.ProjectOwnerEmail}>{item.ProjectOwnerEmail}</option>
                                        })}
                                    </Form.Select>
                                </Form.Group></Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label class="required-field"> Project DMC Member</Form.Label>
                                    <Form.Select aria-label="DMCMemberOwner" id="DMCMemberOwner" name="DMCMemberOwner" value={DMCMemberOwner} onChange={(e) => setDMCMemberOwner(e.target.value)} >
                                        {ProjectDetails.map((item, index) => {
                                            return <option key={index} value={item.ProjectDMCEmail}>{item.ProjectDMCEmail}</option>
                                        })}
                                    </Form.Select>
                                </Form.Group></Col>
                        </Row>
                        <h5> <span class="text-primary">Part A - Change Initiation Section </span></h5>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" >
                                    <Form.Label> Name</Form.Label>
                                    <Form.Control type="text" value={ChangeInitiationOwner} disabled id="ChangeInitiationOwner" name="Name" onChange={(e) => setChangeInitiationOwner(e.target.value)} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" >
                                    <Form.Label> DMC Member (Seeking Change)</Form.Label>
                                    <Form.Control type="text" value={DMCMember} name="Dmcmember" disabled onChange={(e) => setDMCMember(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" >
                                    <Form.Label> Request Date</Form.Label>
                                    <Form.Control type="text" id="ChangeRequestDate" disabled value={ChangeRequestDate} name="ChangeRequestDate" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3" >
                                    <Form.Label> Change Request Number</Form.Label>
                                    <Form.Control type="text" value={ChangeRequestNumber} disabled name="ChangeRequestNumber" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <h5> <span class="text-primary">Description & Details of Changes proposed  </span></h5>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label class="required-field"> Work Package</Form.Label>
                                    <Form.Select aria-label="WorkPackage" id="WorkPackage" name="WorkPackage" value={WorkPackage} onChange={(e) => setWorkPackage(e.target.value)} >
                                        {WorkPackageOptions.map((item, index) => {
                                            return <option key={index} value={item.WorkPackageDetail}>{item.WorkPackageDetail}</option>
                                        })}
                                    </Form.Select>
                                </Form.Group></Col>
                            <Col>
                                <Form.Group className="mb-3" >
                                    <Form.Label></Form.Label>
                                    <Form.Control type="text" id="txtWorkPackage" value={WorkPackage} name="WorkPackage" disabled onChange={(e) =>
                                        (e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label class="required-field"> Existing</Form.Label>
                                    <Form.Control as="textarea" rows={5} value={Existing} name="Existing" onChange={(e) => { setExisting(e.target.value); fetchSelectedProjectData() }} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label class="required-field"> Proposed</Form.Label>
                                    <Form.Control as="textarea" rows={5} value={Proposed} name="Proposed" onChange={(e) => setProposed(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Form.Label>Attachment (Size per file upto 5 MB max (max 5 files allowed), allowed file types are .doc, .pdf, .xls, .xlsx, .mst, .ppt, .dwg) </Form.Label>
                        </Row>
                        <Row>

                            <div>
                                <input
                                    id="attachmentbtn"
                                    type="file"
                                    title="Select or Drag Files here"
                                    onChange={onFileDrop}
                                    disabled={fileList.length >= 5 ? true : false}

                                />
                            </div>
                        </Row><br></br> <Row>
                            <div>
                                {fileList.length > 0 ? (
                                    <div className="files-list">
                                        {fileList.map((item, index) => (
                                            <div className="files-list-map" key={index}>
                                                {/* <button className="files-report-icon" /> */}
                                                <span className="files-note">{item.name}</span>
                                                <button
                                                    className="files-trash-icon"
                                                    onClick={() => fileRemove(item)}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                ) : null}
                            </div>
                        </Row>
                        <br></br>
                        {/* <Row>
                            <Dropzone onChangeStatus={handleChangeStatus} onSubmit={handleAttachmentSubmit} value={UploadedFileLocation}
                                maxFiles={5} multiple={true} canCancel={true} inputContent="Attach a File" styles={{
                                    dropzone: { width: 1000, height: 100, alignItems: 'left' }, dropzoneActive: { borderColor: 'green' },
                                }}
                            />
                        </Row> */}
                        <Row>
                            <Col>
                                <Form.Group className="mb-3" >
                                    <Form.Label class="required-field"> Key Reasons / Justification / Benefits for Change</Form.Label>
                                    <Form.Control as="textarea" rows={5} value={JustificationOfChange} name="JustificationOfChange" onChange={(e) => setJustificationOfChange(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label class="required-field"> Assumptions / Constraints</Form.Label>
                                    <Form.Control as="textarea" rows={5} value={AssumptionsConstraints} name="AssumptionsConstraints" onChange={(e) => setAssumptionsConstraints(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label class="required-field"> Key Risks</Form.Label>
                                    <Form.Control as="textarea" rows={5} value={KeyRisks} name="keyrisks" onChange={(e) => setKeyRisks(e.target.value)} />

                                </Form.Group>
                            </Col>

                        </Row>
                        <Row><br></br></Row>
                        <Row>
                            <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                onClick={(e) => { chanegEvent("TimeAndCost") }} > Next </Button> </div>
                        </Row>
                    </Tab>

                    <Tab eventKey="TimeAndCost" disabled id="TimeAndCost" title="Time And Cost">
                        <Row>
                            <div>
                                <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                    onClick={(e) => { chanegEvent("ChangeApproval1") }} > Next </Button> </div>
                                <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "left" }} size="sm"
                                    onClick={(e) => { chanegEvent("ChangeInitiation") }} > Prev </Button> </div>

                            </div>
                        </Row>
                        <h5> <span class="text-primary">Time and Cost target</span></h5>
                        <Row>
                            <table class="table table-small">
                                <thead className="table-head">
                                    <tr>
                                        <th></th>
                                        <th>Original Commitment</th>
                                        <th>R1</th>
                                        <th>R2</th>
                                        <th>R3</th>
                                        <th>R4</th>
                                    </tr>
                                </thead>
                                <tbody className="table-body">
                                    <tr>
                                        <td>   <Form.Label>Change No. & Dt</Form.Label></td>
                                        <td> <Form.Control type="text" value={OriginalchangenoAndDate} name="OriginalchangenoAndDate" />
                                        </td>
                                        <td> <Form.Control type="text" value={R1_ChangeNoAndDate} disabled name="R1_ChangeNoAndDate" />
                                        </td>
                                        <td> <Form.Control type="text" value={R2_ChangeNoAndDate} disabled name="R2_ChangeNoAndDate" />
                                        </td>
                                        <td> <Form.Control type="text" value={R3_ChangeNoAndDate} disabled name="R3_ChangeNo" />
                                        </td>
                                        <td> <Form.Control type="text" value={R4_ChangeNoAndDate} disabled name="R4_ChangeNo" />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>   <Form.Label class="required-field" > Time</Form.Label></td>
                                        <td>  <Form.Control type="date" id="Original_time" className="textbox-n" placeholder={todaysdate} value={Original_time} disabled={MaxRevisionNo == 0 ? false : true} name="Original_time" onBlur={(e) => chkValdate(e.target.value, "Original_time")} onChange={(e) => { setOriginal_time(e.target.value) }} />

                                        </td>
                                        <td>
                                            <Form.Control type="date" id="R1_Time" className="textbox-n" placeholder={todaysdate} value={R1_Time} disabled={MaxRevisionNo == 0 ? false : true} name="R1_Time" onBlur={(e) => chkValdate(e.target.value, "R1_Time")} onChange={(e) => { setR1_Time(e.target.value) }} />

                                        </td>
                                        <td>
                                            <Form.Control type="date" id="R2_Time" className="textbox-n" placeholder={todaysdate} value={R2_Time} disabled={MaxRevisionNo == 1 ? false : true} name="R2_Time" onBlur={(e) => chkValdate(e.target.value, "R2_Time")} onChange={(e) => { setR2_Time(e.target.value) }} />
                                        </td>
                                        <td>
                                            <Form.Control type="date" id="R3_Time" className="textbox-n" placeholder={todaysdate} value={R3_Time} disabled={MaxRevisionNo == 2 ? false : true} name="R3_Time" onBlur={(e) => chkValdate(e.target.value, "R3_Time")} onChange={(e) => { setR3_Time(e.target.value) }} />
                                        </td>
                                        <td>
                                            <Form.Control type="date" id="R4_Time" className="textbox-n" placeholder={todaysdate} value={R4_Time} disabled={MaxRevisionNo == 3 ? false : true} name="R4_Time" onBlur={(e) => chkValdate(e.target.value, "R4_Time")} onChange={(e) => { setR4_Time(e.target.value) }} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td> <Form.Label class="required-field"> Cost</Form.Label></td>
                                        <td> <Form.Control type="number" value={Original_Cost} disabled={MaxRevisionNo == 0 ? false : true} name="Original_Cost" onChange={(e) => { setOriginal_Cost(e.target.value); validateNumber(e.target.value, "Original_Cost") }} />
                                        </td>
                                        <td> <Form.Control type="number" value={R1_Cost} disabled={MaxRevisionNo == 0 ? false : true} name="R1_Cost" onChange={(e) => { setR1_Cost(e.target.value); validateNumber(e.target.value, "R1_Cost") }} />
                                        </td>
                                        <td> <Form.Control type="number" value={R2_Cost} disabled={MaxRevisionNo == 1 ? false : true} name="R2_Cost" onChange={(e) => { setR2_Cost(e.target.value); validateNumber(e.target.value, "R2_Cost") }} />
                                        </td>
                                        <td> <Form.Control type="number" value={R3_Cost} disabled={MaxRevisionNo == 2 ? false : true} name="R3_Cost" onChange={(e) => { setR3_Cost(e.target.value); validateNumber(e.target.value, "R3_Cost") }} />
                                        </td>
                                        <td> <Form.Control type="number" value={R4_Cost} disabled={MaxRevisionNo == 3 ? false : true} name="R4_Cost" onChange={(e) => { setR4_Cost(e.target.value); validateNumber(e.target.value, "R4_Cost") }} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <p><span class="font-weight-light">*Enter approved values for prior changes (If Applicable) and estimated values for present change</span></p>
                        </Row>
                        <Row><br></br></Row>
                        <Row>
                            <div>
                                <div class="float-start m-1"> <Button class="btn-primary" style={{ float: "left" }} size="sm"
                                    onClick={(e) => { chanegEvent("ChangeInitiation") }} > Prev </Button> </div>
                                <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                    onClick={(e) => { chanegEvent("ChangeApproval1") }} > Next </Button> </div>
                            </div>
                        </Row>
                    </Tab>
                    <Tab eventKey="ChangeApproval1" disabled title="Change Approval-1">
                        <Row>
                            <div>
                                <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                    onClick={(e) => { chanegEvent("ChangeApproval2") }} > Next </Button> </div>
                                <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "left" }} size="sm"
                                    onClick={(e) => { chanegEvent("TimeAndCost") }} > Prev </Button> </div>

                            </div>
                        </Row>
                        <h5> <span class="text-primary">Change Approval 1</span></h5>
                        <Row>
                            <Col>

                                <Form.Group className="mb-3">
                                    <Form.Label class="required-field"> Change Feasibility inputs from relevant Stakeholders</Form.Label>
                                    <Form.Select aria-label="ChangeFesibility_releventStakeholders" name="ChangeFesibility_releventStakeholders" id="ChangeFesibility_releventStakeholders" value={ChangeFesibility_releventStakeholders} onChange={(e) => { setChangeFesibility_releventStakeholders(e.target.value); ChangeFeasibilityDrpdownChange() }} >
                                        {YesNoOptions.map((item, index) => {
                                            return <option key={index} value={item.value}>{item.label}</option>
                                        })}
                                    </Form.Select>

                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Godrej Construction Sections Team &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</Form.Label>
                                    <Form.Select aria-label="constructionSectionTeam" disabled={ChangeFesibility_releventStakeholders == "Yes" ? false : true} id="constructionSectionTeam" name="constructionSectionTeam" value={tmpGodrejConstructionSections} onChange={(e) => { settmpGodrejConstructionSections(e.target.value); getSectionEmailOptions() }} >
                                        {ConstructionSectionTeamOptions.map((item, index) => {
                                            return <option key={index} value={item.SectionTeamValue}>{item.SectionTeam}</option>
                                        })}
                                    </Form.Select>
                                </Form.Group></Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Godrej Construction Sections Email Id &nbsp; &nbsp;&nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</Form.Label>
                                    <Form.Select aria-label="constructionSectionEmail" id="constructionSectionEmail" disabled={ChangeFesibility_releventStakeholders.toUpperCase() == "YES" ? false : true} name="constructionSectionEmail" value={tmpGodrejConstructionSectionsEmail} onChange={(e) => { settmpGodrejConstructionSectionsEmail(e.target.value); addsectionArray(e.target.value) }} >
                                        {GodrejConstructionSectionsEmailOptions.map((item, index) => {
                                            return <option key={index} value={item.SMEmailIDValue}>{item.SMEmailID}</option>
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row id="sectiontablerow" class={SelectedConstructionSection.length == 0 ? "invisible" : "visible"}>
                            <Table id="sectiontable"   >
                                <thead className="table-head">
                                    <tr>
                                        <th>SectionTeam</th>
                                        <th>SectionEmail</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody className="table-body">
                                    {SelectedConstructionSection.map((item, index) =>
                                        <tr key={index}>
                                            <td>{item.SectionTeam}</td>
                                            <td>{item.SectionEmail}</td>
                                            <td>
                                                <Button size="sm" variant="outline-danger" value={item.SectionEmail} onClick={(e) => removesectionArray(e.target.value)} > Remove </Button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </Table>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label> Other G&B Divisions (including End User)</Form.Label>
                                    <Form.Select aria-label="OtherDivision" id="OtherDivision" name="OtherDivision" value={OtherDivision} onChange={(e) => { setOtherDivision(e.target.value); getOtherDivisionEmailOptions() }} >
                                        {OtherDivisionOptions.map((item, index) => {
                                            return <option key={index} value={item.Division}>{item.Division}</option>
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label> Other Divisions Email Id</Form.Label>
                                    <Form.Select aria-label="OtherDivisionEmail" id="OtherDivisionEmail" name="OtherDivisionEmail" value={OtherDivisionEmail} onChange={(e) => setOtherDivisionEmail(e.target.value)} >
                                        {OtherDivisionEmailOptions.map((item, index) => {
                                            return <option key={index} value={item.EmailId}>{item.EmailId}</option>
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>

                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label class="required-field"> PDA SPOC</Form.Label>
                                    <Form.Select aria-label="pdaspoc" id="pdaspoc" name="pdaspoc" value={PDASpocName} onChange={(e) => { setPDASpocName(e.target.value); getPDASpocEmail() }} >
                                        {PDASpocNameOptions.map((item, index) => {
                                            return <option key={index} value={item.PDAname}>{item.PDAname}</option>
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label class="required-field"> Send to Finance team?</Form.Label>
                                    <Form.Select name="IsFinance" id="IsFinance" value={IsFinance} onChange={(e) => { setIsFinance(e.target.value); ChangeFinanceDropdown() }} >
                                        {YesNoOptions.map((item, index) => {
                                            return <option key={index} value={item.value}>{item.label}</option>
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label> Finance Team</Form.Label>
                                    <Form.Select aria-label="financeteam" id="financeteam" name="financeteam" disabled={IsFinance.toUpperCase() == "YES" ? false : true} value={FinanceMemberName} onChange={(e) => { setFinanceMemberName(e.target.value); getFinanceMemberEmail() }} >
                                        {FinanceMemberNameOptions.map((item, index) => {
                                            return <option key={index} value={item.Fname}>{item.Fname}</option>
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label class="required-field"> All Feasibility Reports annexed and their impacts considered in the change request: (Yes / No):</Form.Label>
                                    <Form.Select name="feasibilityannexed" id="ChangeFeasibilityAnnexed" value={ChangeFeasibilityAnnexed} onChange={(e) => setChangeFeasibilityAnnexed(e.target.value)} >
                                        {YesNoOptions.map((item, index) => {
                                            return <option key={index} value={item.value}>{item.label}</option>
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row><br></br></Row>
                        <Row>
                            <div>
                                <div class="float-start m-1"> <Button class="btn-primary" style={{ float: "left" }} size="sm"
                                    onClick={(e) => { chanegEvent("TimeAndCost") }} > Prev </Button> </div>
                                <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                    onClick={(e) => { chanegEvent("ChangeApproval2") }} > Next </Button> </div>
                            </div>
                        </Row>
                    </Tab>
                    <Tab eventKey="ChangeApproval2" disabled title="Change Approval-2">
                        <Row>
                            <div class="float-end m-1"> <Button class="btn-primary" style={{ float: "right" }} size="sm"
                                onClick={(e) => { chanegEvent("ChangeApproval1") }} > Prev </Button> </div>

                        </Row>
                        <h5> <span class="text-primary">Change Approval 2</span></h5>
                        <Form.Group className="mb-3">
                            <Row>
                                <Form.Label className="FeedbackLabel" >Stop Work</Form.Label>

                                {/* <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Stop work</Form.Label>
                                        <Form.Select name="StopWork" id="StopWork" value={StopWork} onChange={(e) => setStopWork(e.target.value)} >
                                            <option value="No">No</option>
                                            <option value="Yes">Yes</option>

                                        </Form.Select>
                                    </Form.Group>
                                </Col> */}
                                <Col> <Form.Check
                                    type="switch"
                                    id="stopwork"
                                    onChange={onSwitchAction}
                                // onClick={(e) => setStopWork(e.target.value == true? "Yes" : "No")}
                                /></Col>

                                {/*   <Col>
                             <Form.Switch
                                 onChange={onSwitchAction}
                                id="stopwork"
                                value={StopWork}
                               
                            />
                        </Col> */}
                                <Col>
                                    <Form.Label>If Yes, Number of Days</Form.Label>
                                    <Form.Control type="number" oninput="validity.valid||(value='');" name="stopworkdays" min={0} step={1} id="NoofDays" value={NoofDays} disabled={StopWork === "No" ? true : false} onChange={(e) => { setNoofDays(e.target.value); validateNumberofDays(e.target.value) }} />
                                </Col>
                            </Row>
                            <span class="text-primary">Change Initiation</span>

                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Change Initiated By</Form.Label>
                                        <Form.Control type="text" value={ChangeInitiationBy} disabled name="changeInitiator" ></Form.Control>

                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Change Initiated On</Form.Label>
                                        <Form.Control type="text" value={ChangeInitiationOn} name="ChangeInitiationOn" disabled="true"  ></Form.Control>

                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label class="required-field"> Stop work approved by DMC</Form.Label>
                                        <Form.Select name="stopworkapprovedbydmc" value={StopWorkApprovedByDMC} disabled onChange={(e) => setStopWorkApprovedByDMC(e.target.value)} >
                                            {YesNoOptions.map((item, index) => {
                                                return <option key={index} value={item.value}>{item.label}</option>
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>DMC Member</Form.Label>
                                        <Form.Control type="text" value={DMCMemberEmail} disabled="true" name="DMCMember" />

                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Date</Form.Label>
                                        <Form.Control type="text" value={StopWorkApprovedByDmcOn} name="StopWorkApprovedByDmcOn" disabled="true" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <span class="text-primary">Temporary Stop Work (If Applicable)</span>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label class="required-field" > Stop work Approved by Client / Client SPOC</Form.Label>
                                        <Form.Select name="stopworkapprovedbyPda" value={StopWorkApprovedByPda} disabled onChange={(e) => setStopWorkApprovedByPda(e.target.value)}  >
                                            {YesNoOptions.map((item, index) => {
                                                return <option key={index} value={item.value}>{item.label}</option>
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Approver</Form.Label>
                                        <Form.Control type="text" value={PDAStopWorkApprover} disabled="true" name="PdaHead" onChange={(e) => setPDAStopWorkApprover(e.target.value)} />

                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row>

                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Remarks</Form.Label>
                                        <Form.Control type="text" value={PdastopworkapprovalRemarks} name="PdastopworkapprovalRemarks" disabled="true" onChange={(e) => setPdastopworkapprovalRemarks(e.target.value)} />

                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Date</Form.Label>
                                        <Form.Control type="text" value={StopWorkApprovedByPdaOn} name="StopWorkApprovedByPdaOn" disabled="true" />

                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label class="required-field"> Stop work Approved by GC Business Head</Form.Label>
                                        <Form.Select name="StopWorkApprovedByGC" value={StopWorkApprovedByGC} disabled onChange={(e) => StopWorkApprovedByGC(e.target.value)}  >
                                            {YesNoOptions.map((item, index) => {
                                                return <option key={index} value={item.value}>{item.label}</option>
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Approver</Form.Label>
                                        <Form.Control type="text" value={GCStopWorkApprover} disabled="true" name="GCStopWorkApprover" onChange={(e) => setGCStopWorkApprover(e.target.value)} />

                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row>

                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Remarks</Form.Label>
                                        <Form.Control type="text" value={GCstopworkapprovalRemarks} name="GCstopworkapprovalRemarks" disabled="true" onChange={(e) => setGCstopworkapprovalRemarks(e.target.value)} />

                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Date</Form.Label>
                                        <Form.Control type="text" value={GCStopWorkApproveDate} name="GCStopWorkApproveDate" disabled="true" />

                                    </Form.Group>
                                </Col>
                            </Row>
                            <span class="text-primary">Change Approval</span>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label class="required-field"> Approved By DMC Of Initiator</Form.Label>
                                        <Form.Select name="ApprovedByDmc" value={ApprovedByDmc} disabled onChange={(e) => setApprovedByDmc(e.target.value)}   >
                                            {YesNoOptions.map((item, index) => {
                                                return <option key={index} value={item.value}>{item.label}</option>
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Approver</Form.Label>
                                        <Form.Control type="text" value={DMCMember} disabled="true" name="Dmcmember" onChange={(e) => setDMCMember(e.target.value)} />

                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>

                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Remarks</Form.Label>
                                        <Form.Control type="text" value={RemarksforRejection} name="RemarksforRejection" disabled="true" onChange={(e) => setRemarksforRejection(e.target.value)} />

                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Date</Form.Label>
                                        <Form.Control type="text" value={ApprovedByDmcDate} name="ApprovedByDmcDate" disabled="true" />

                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label class="required-field"> Approved By PDA Team</Form.Label>
                                        <Form.Select name="ApprovedByPDATeam" value={ApprovedByPDATeam} disabled onChange={(e) => setApprovedByPDATeam(e.target.value)}  >
                                            {YesNoOptions.map((item, index) => {
                                                return <option key={index} value={item.value}>{item.label}</option>
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Approver</Form.Label>
                                        <Form.Control type="text" value={PDASpocName} disabled="true" name="PDASpocName" onChange={(e) => setPDASpocName(e.target.value)} />

                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>

                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Remarks</Form.Label>
                                        <Form.Control type="text" value={RemarkByPDA} name="RemarkByPDA" disabled="true" onChange={(e) => setRemarkByPDA(e.target.value)} />

                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Date</Form.Label>
                                        <Form.Control type="text" value={ApprovedByPDATeamOn} name="ApprovedByPDATeamOn" disabled="true" />

                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label class="required-field"> Approved By Finance Team</Form.Label>
                                        <Form.Select name="ApprovedByFinance" value={ApprovedByFinance} disabled onChange={(e) => setApprovedByFinance(e.target.value)}  >
                                            {YesNoOptions.map((item, index) => {
                                                return <option key={index} value={item.value}>{item.label}</option>
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Approver</Form.Label>
                                        <Form.Control type="text" value={FinanceApprover} disabled="true" name="FinanceApprover" onChange={(e) => setFinanceApprover(e.target.value)} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Remarks</Form.Label>
                                        <Form.Control type="text" value={RemarkByFinance} name="RemarkByFinance" disabled="true" onChange={(e) => setRemarkByFinance(e.target.value)} />
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Date</Form.Label>
                                        <Form.Control type="text" value={ApprovedByFinanceOn} name="ApprovedByFinanceOn" disabled="true" />

                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label class="required-field"> Change Accepted by DMC Member Heading Project / Initiative OR BH / SPOC of Other Division seeking change</Form.Label>
                                        <Form.Select name="ChangeAcceptedbyDMC" value={ChangeAcceptedbyDMC} disabled onChange={(e) => setChangeAcceptedbyDMC(e.target.value)}  >
                                            {YesNoOptions.map((item, index) => {
                                                return <option key={index} value={item.value}>{item.label}</option>
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Approver</Form.Label>
                                        <Form.Control type="text" value={DMCMemberOwner} disabled="true" name="DMCMemberOwner" onChange={(e) => setDMCMemberOwner(e.target.value)} />

                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>

                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Remarks</Form.Label>
                                        <Form.Control type="text" value={RemarkByProjDmc} name="RemarkByProjDmc" disabled="true" onChange={(e) => setRemarkByProjDmc(e.target.value)} />

                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Date</Form.Label>
                                        <Form.Control type="text" value={ProjDMCAcceptanceDate} name="ProjDMCAcceptanceDate" disabled="true" />

                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label class="required-field"> Change Timeline Validated by P&DA</Form.Label>
                                        <Form.Select name="ChangeValidatedByPDA" value={ChangeValidatedByPDA} disabled onChange={(e) => setChangeValidatedByPDA(e.target.value)}   >
                                            {YesNoOptions.map((item, index) => {
                                                return <option key={index} value={item.value}>{item.label}</option>
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Approver</Form.Label>
                                        <Form.Control type="text" value={PDAApprover} disabled="true" name="PDAApprover" onChange={(e) => setPDAApprover(e.target.value)} />

                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>

                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Remarks</Form.Label>
                                        <Form.Control type="text" value={RemarkByPdaHead} name="RemarkByPdaHead" disabled="true" onChange={(e) => setRemarkByPdaHead(e.target.value)} />

                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Date</Form.Label>
                                        <Form.Control type="text" value={ApprovedByPdaOn} name="ApprovedByPdaOn" disabled="true" />

                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label class="required-field"> Change Approved by GC Business Head</Form.Label>
                                        <Form.Select name="ChangeApprovedbyGC" value={ChangeApprovedbyGC} disabled onChange={(e) => setChangeApprovedbyGC(e.target.value)} >
                                            {YesNoOptions.map((item, index) => {
                                                return <option key={index} value={item.value}>{item.label}</option>
                                            })}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Approver</Form.Label>
                                        <Form.Control type="text" value={GCApprover} disabled="true" name="GCApprover" onChange={(e) => setGCApprover(e.target.value)} />

                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>

                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Remarks</Form.Label>
                                        <Form.Control type="textarea" value={RemarkByBH} name="RemarkByBH" disabled="true" onChange={(e) => setRemarkByBH(e.target.value)} />

                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group className="mb-3" >
                                        <Form.Label>Date</Form.Label>
                                        <Form.Control type="text" value={GCHeadApprovalDate} name="GCHeadApprovalDate" disabled="true" />

                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form.Group>

                        <Container style={{ display: "flex", justifyContent: "center" }}>
                            <Stack direction="horizontal" gap={4}>
                                <Button variant="success" size="lg" value="submit" type="submit">Submit</Button>
                                <Button variant="danger" onClick={btnCancelClick} size="lg" type="reset">Cancel</Button>
                            </Stack>
                        </Container>
                        <Row><br></br></Row>
                        <Row>
                            <div class="float-start m-1"> <Button class="btn-primary" style={{ float: "left" }} size="sm"
                                onClick={(e) => { chanegEvent("ChangeApproval1") }} > Prev </Button> </div>

                        </Row>
                    </Tab>
                </Tabs>
            </Form>
        </Container>

    )

};



